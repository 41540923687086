import React, { Component } from 'react'

class Button extends Component{
    
    click(e){
        if(typeof e === "function")
        {
            e()
        }
    }
    render(){
        const {onClick, text} = this.props
        return(
            <button onClick={()=>this.click(onClick)}>{text}</button>
        )
    }
}
export default Button