export function PrototipeProperties($data)
{
    const PopUp = $data
    const {
        id,
        container,
        icon,
        message,
        type,
        timer,
        replace,
        animationStartDuration,
        animationEndDuration,
        animationStart,
        animationEnd,
        ownClass,
        ownButton
    } = PopUp

        PopUp.id = id
        PopUp.container = container || "Right"
        PopUp.timer = typeof timer === "number"? timer : 3000
        PopUp.icon = icon || null
        PopUp.message = message || null
        PopUp.type = type || null
        PopUp.replace = replace || false
        PopUp.Remove = false
        PopUp.Hide = false
        PopUp.ownClass = ownClass
        PopUp.animationStart = animationStart || "Popup-show-"+PopUp.container
        PopUp.animationEnd = animationEnd || "Popup-hide-"+PopUp.container
        PopUp.animationStartDuration = animationStartDuration || "0.5s"
        PopUp.animationEndDuration = animationEndDuration || "0.5s"
        PopUp.ownButton =  ownButton ? {
                                text : ownButton.text,
                                onClick : ownButton.onClick
                            } : null
    return PopUp
}