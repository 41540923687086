import Errors from './errors.json'
import './variables'

export {Errors} 

let mobileCheck = function() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    if(window.innerWidth < 1280)
    {
        return true;
    }
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}
window.isMobile = mobileCheck();



window.ScrollMain = (el,callback,resize)=>{
    return new ScrollMain(el,callback,resize)
};
class ScrollMain{
    constructor(el = window,callback,resize)
    {
        this.el = el;
        this.resize = resize || function(){};
        this.event = (e)=>this.start(e,callback)
        if(window.isMobile){
            this.touchEvent = window.TouchEvent(this.el).event(this.event)
        }
        this.el.addEventListener('wheel',this.event)
        window.addEventListener('resize',this.resize)
        this.end = function(){
            if(this.touchEvent)
            {
                this.touchEvent.end();
            }
            this.el.removeEventListener('wheel',this.event);
            window.removeEventListener('resize',this.resize)
        };
        return this;
    }
    start(e,callback=()=>{}){
        callback(e)
    }
}
