import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

// ~  App  ~ \\
import App from '@/main/App';

// ~  Modules  ~ \\
import PopUps from '@modules/popup/Containers'
import reportWebVitals from '@modules/scripts/reportWebVitals';
import "@modules/query/query"
import "@modules/variables"


// ~  Style  ~ \\
import '@style/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PopUps/>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
