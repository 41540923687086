// ~  Modules  ~ \\
import { lazy } from "react"
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";

// ~  Assets  ~ \\
import { Footer } from '@/footer/footer';
import Contract, { pages } from "./contract/contract";
const  Admin = lazy(() => import('@/admin'));


export default function App() {
  return (
      <Routes>
          <Route path="/" element={<Layout />}>
            {
              pages.map((e,i)=>(
                <Route index key={i} path={e.path?e.path:window.strToUrl(e.name)} element={<Contract {...e} />} />
              ))
            }
            <Route path="" element={<Navigate to={"/"+window.strToUrl(pages[0].name)}/>} />
            <Route path="completed" element={<Contract final={true} />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route path="admin/*" element={<Admin/>} />
          <Route path="login/*" element={<Admin/>} />
      </Routes>
  );
}

function Layout() {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div className="not-found">
      <h1>¯\_(ツ)_/¯</h1>
      <h1>404 Stránka sa nenašla</h1>
      <Link to="/">Prejsť na domovskú stránku</Link>
    </div>
  );
}
